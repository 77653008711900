<template>
  <div
    class="listing-item"
    @mouseenter="onToggleInfoWindow(property, property._id)"
    @mouseleave="closeInfoWindo()"
  >
    <div
      @click="onClickListing(property._id)"
      class="listing-overlay-link slide-item"
    ></div>
    <!-- Listing img container -->
    <div class="listing-img-container transform-scal">
      <!-- Badges -->
      <div class="listing-badges">
        <div v-if="ownerAndOwnerProperties">
          <div v-if="property.active == 1">
            <span class="property-status is-active">نشط</span>
          </div>
          <div v-else>
            <span class="property-status is-inactive">منتهي</span>
          </div>
        </div>
        <div class="d-flex" v-if="usedInEqualProperties">
          <b-dropdown
            v-if="ownerAndOwnerProperties"
            variant="link"
            no-caret
            class="options-menu"
          >
            <template v-slot:button-content>
              <i class="hi-options-v"></i> <span class="sr-only">خيارات</span>
            </template>
            <b-dropdown-item @click.prevent="refreshProperty(property._id)"
              ><i class="hi-refresh ml-2"></i>
              تحديث
            </b-dropdown-item>
            <b-dropdown-item :to="`/add-property/${property._id}`"
              ><i class="hi-edit ml-2"></i> تعديل
            </b-dropdown-item>
            <b-dropdown-item
              href="#"
              class="delete"
              @click="deleteThisProperty(property._id)"
              ><i class="hi-trash ml-2"></i> حذف
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!-- Images -->
      <div
        style="z-index: 5"
        class="listing-img-content d-flex align-items-center"
      >
        <span v-if="property.price" class="listing-price">
          {{ numberWithCommas(property.price) }}
          <span class="unit-item"> ريال </span>
          <span class="unit-item" v-if="unitItem">
            / {{ payTypeOptions[property.payType].text }}</span
          >
        </span>
        <span v-else class="listing-price"
          ><span class="unit-item">علي السوم</span></span
        >
        <a
          v-if="!isOwner"
          :class="{
            'favorite-btn in-favorite':
              isLoggedIn && isInFavorite(property._id, user._id)
          }"
          @click.prevent="toggleFavorite(property)"
          ><span class="hi-bookmark2-o icon"></span
        ></a>
      </div>
      <!-- Properties -->
      <div class="property-slider" v-if="usedInEqualProperties">
        <slick
          v-if="isImages"
          ref="listingSlider"
          :options="slickOptions"
          @lazyLoaded="slickHandleLazyLoaded"
        >
          <div
            v-for="(img, index) in property.images"
            :key="index"
            class="listing-img-container slide-item"
          >
            <div class="slide-bg">
              <img
                class="rounded-circle"
                :data-lazy="property.images[index].smallImage.path"
              />
            </div>
          </div>
        </slick>
      </div>
      <!-- Small images -->
      <div
        :class="[
          'property-slider',
          { 'property-slider--land': property.type.nameEn === 'land' }
        ]"
      >
        <img v-if="isImages" :src="property.images[0].smallImage.path" />
      </div>
    </div>
    <!-- Listing content -->
    <div class="listing-content">
      <div class="listing-title">
        <!-- Title content -->
        <div class="title-content">
          <h4 class="font-weight-bold" v-if="isPropertyAndPropertyType">
            <span>{{ property.type.nameAr }}</span
            ><span v-if="hasPurpose(property.type.nameEn)">{{
              realEstatePurpose
            }}</span
            ><span v-if="property.status">{{ property.status.nameAr }}</span>
          </h4>
          <div class="listing-address popup-gmaps" v-if="property.address">
            <i class="hi-map-pin"></i>
            <span>{{ property.address }}</span>
          </div>
        </div>
        <!-- Listing features -->
        <ul class="listing-features">
          <li v-if="property.space">
            <span class="icon hi-area"></span>
            <span
              >{{ property.space }}
              <span class="area-unit">م<span>2</span></span></span
            >
          </li>
          <li v-if="property.numberOfRooms">
            <span class="icon hi-bed"></span>
            <span>{{ property.numberOfRooms }}</span>
          </li>
          <li v-if="property.numberOfBathRoom">
            <span class="icon hi-bathroom"></span
            ><span>{{ property.numberOfBathRoom }}</span>
          </li>
        </ul>
        <!-- Listing address -->
        <div
          class="listing-address popup-gmaps d-inline-flex align-items-center"
        >
          <span class="hi-time pl-1 font-18 brand-color"></span>
          <span class="font-9"
            >{{ moment(property.createdAt).fromNow() }}
          </span>
        </div>
        <div
          v-if="!OwnerProperitesViewAndTags"
          class="my-2 margin-5 d-flex text-center align-items-center"
        >
          <span v-if="property.tags.includes('negotiable')" class="info-status"
            >قابل للتفاوض</span
          >
          <span v-if="property.tags.includes('fromOwner')" class="info-status"
            >المالك مباشرة</span
          >
          <span
            v-if="property.tags.includes('allTheFutures')"
            class="info-status"
            >للتقبيل</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import config from '@/config'
import { realEstateService } from '../../services'
import { EventBus } from '@/services/EventBus'
import Slick from 'vue-slick'
// Style
import '@/styles/property/listing-item.scss'
import '@/styles/common/slick-slider.scss'
// Moment
import moment from 'moment-timezone'
import 'moment/locale/ar'

export default {
  layout: 'map',
  components: {
    Slick
  },
  props: [
    'property',
    'isInfavoritesView',
    'isOwnerProperitesView',
    'usedIn',
    'isAgent'
  ],
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        lazyLoad: 'ondemand',
        rtl: true
      }
    }
  },
  computed: {
    ...mapState('accountState', [
      'user',
      'status',
      'favorites',
      'favoritesIds',
      'configs'
    ]),
    //
    isLoggedIn() {
      return this.status.loggedIn
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    isOwner() {
      if (this.property && this.property.account) {
        return this.user && this.user._id == this.property.account.id
      }
      return false
    },
    imagesSmall() {
      const imagesSmall = this.property.images
      const index = this.property.mainImageIndex
      return index
        ? imagesSmall.unshift(imagesSmall.splice(index, 1)[0])
        : imagesSmall
    },
    ownerAndOwnerProperties() {
      return this.isOwner && this.isOwnerProperitesView
    },
    OwnerProperitesViewAndTags() {
      return this.isOwnerProperitesView && this.property.tags
    },
    usedInEqualProperties() {
      return this.usedIn == 'properties'
    },
    unitItem() {
      return (
        (this.property.status &&
          this.property.status.nameEn == 'rent' &&
          this.property.payType == 0) ||
        this.property.payType == 1 ||
        this.property.payType == 2
      )
    },
    isImages() {
      return this.property.images.length !== 0
    },
    isPropertyAndPropertyType() {
      return this.property && this.property.type
    },
    realEstatePurpose() {
      if (typeof this.property.purpose === 'string') {
        const purpose = this.configs.realEstatePurpose.find(
          (purpose) => purpose._id === this.property.purpose
        )
        return purpose?.nameAr ?? ''
      } else {
        return this.property.purpose?.nameAr ?? ''
      }
    }
  },
  methods: {
    ...mapActions('accountState', ['addToFavorite', 'deleteFromFavorite']),
    ...mapMutations('accountState', ['addToFavorites', 'deleteFromFavorites']),
    ...mapMutations('realEstateState', ['refreshRealEstate']),
    //
    onToggleInfoWindow: function (property, id) {
      if (this.isAgent) return
      if (this.usedIn == 'properties')
        EventBus.$emit('on-toggle-infoWindow', property, id)
    },
    moment(date) {
      return moment(date)
    },
    closeInfoWindo() {
      if (this.usedIn == 'properties') EventBus.$emit('on-close-infoWindow')
    },
    slickHandleLazyLoaded(event, slick, image, imageSource) {
      image.attr('src', '')
      image.parent().css('background-image', 'url("' + imageSource + '")')
    },
    async refreshProperty(id) {
      try {
        await realEstateService.refreshRealEstate(id)
        this.$toasted.show('تم تحديث العقار بنجاح!', {
          duration: 2000,
          type: 'success',
          icon: 'hi-check'
        })
        this.refreshRealEstate(id)
      } catch {
        this.$toasted.show('يرجى المحاولة مرة اخرى', {
          duration: 2000,
          type: 'success',
          icon: 'hi-check'
        })
      }
    },
    deleteThisProperty(id) {
      this.$emit('on-delete-property', id)
    },
    // Favorites Methods ---------------------
    // eslint-disable-next-line
    isInFavorite(realestate, user) {
      // find favorite by user and realestate
      const favorite = this.favorites.find((fav) => {
        return fav.realestate._id === realestate
      })
      if (favorite && this.favoritesIds && this.favoritesIds.length) {
        return this.favoritesIds.includes(realestate)
      }
    },
    toggleFavorite(property) {
      let { _id: id } = property
      if (this.isLoggedIn) {
        this.isInFavorite(id, this.user._id)
          ? this.onDeleteFromFavorite(id, this.user._id)
          : this.onAddToFavorite(property)
      } else {
        this.$toasted.show('لابد من تسجيل الدخول أولاً', {
          duration: 2000,
          icon: 'hi-check'
        })
      }
    },
    async onAddToFavorite(property) {
      try {
        const response = await this.addToFavorite({
          realestate: property._id,
          user: this.user._id
        })
        if (response.data.favorite) {
          this.addToFavorites(response.data.favorite)
          this.$toasted.show('تم إضافة العقار لمفضلتك!', {
            duration: 2000,
            type: 'success',
            icon: 'hi-check'
          })
        }
      } catch (error) {
        this.$toasted.show(error, {
          duration: 2000,
          type: 'error',
          icon: 'hi-close'
        })
      }
    },
    async onDeleteFromFavorite(realestate, user) {
      try {
        await this.deleteFromFavorite({ realestate, user })
        this.$toasted.show('تم حذف العقار من المفضلة', {
          duration: 2000,
          icon: 'hi-check'
        })
        this.deleteFromFavorites({ realestate, user })
        if (this.isInfavoritesView && this.usedIn == 'properties')
          this.$emit('on-get-favorties')
      } catch (error) {
        this.$toasted.show(error, {
          duration: 2000,
          type: 'error',
          icon: 'hi-close'
        })
      }
    },
    onClickListing(propertyId) {
      // Close info window
      this.closeInfoWindo()
      this.$router.push({
        name: 'property',
        params: { id: propertyId, images360: 0 }
      })
    },
    numberWithCommas(x) {
      return realEstateService.numberWithCommas(x)
    },
    hasPurpose(type) {
      return realEstateService.hasPurpose(type)
    }
  }
}
</script>
